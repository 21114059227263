import {useState} from "react";
// import OpenAI from "openai";
import './ChatComponent.css'
import WindowAd from "./WindowAd.js";
import MenuBottom from "./MenuBottom";
import VkAds from "./vkAds";
import bridge from '@vkontakte/vk-bridge';
import people from './image/people.png'
import letter from './image/letterF.png'
import ErrorScreen from "./ErrorScreen";


const ChatComponent = () => {
  const [message, setMessage] = useState(null);
  const [nameSend, setNameSend] = useState('')
  const [topic, setTopic] = useState('')
  const [nameCustomer, setNameCustomer] = useState('')
  const [mood, setMood] = useState('')
  const [smile, setSmile] = useState(false)
  const [visAd, setVisAd] = useState(false)
  const [visBottom, setVisBottom] = useState(false)
  const [visStartScreen, setVisStartScreen] = useState(true)

  const [textHead, setTextHead] = useState("Заполните все поля:")

  const [empty, setEmpty] = useState(0)

  const [visError, setVisError] = useState(false)

  const handleSubmit = async () => {

    try {
      const res = await fetch('https://tranquil-citadel-64673-5b2d65271b20.herokuapp.com/congrat/ask', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          topic: topic,
          nameSend: nameSend,
          nameCustomer: nameCustomer,
          mood: mood,
          smile: smile
        })
      });

      if (!res.ok) {
        setVisError(true)
        setTimeout(()=>{setVisError(false)},1500)
        setVisAd(false);
        setNameSend(''); // Сброс состояния на пустую строку
        setTopic('');
        setNameCustomer('');
        setMood('');
        setSmile(false);
        throw new Error(`HTTP error! status: ${res.status}`);
      } else {
        setVisAd(false);
        setNameSend(''); // Сброс состояния на пустую строку
        setTopic('');
        setNameCustomer('');
        setMood('');
        setSmile(false);
      }

      const data = await res.json();
      // setResponse(data.choices[0].text);
      // console.log(data);
      setMessage(data);
    } catch (error) {
      setVisError(true)
      setVisAd(false);
      setNameSend(''); // Сброс состояния на пустую строку
      setTopic('');
      setNameCustomer('');
      setMood('');
      setSmile(false);
      setTimeout(()=>{setVisError(false)},3000)
      console.error('There was an error with the fetch operation:', error);
    }
  };


  async function main() {
    if (
      !nameSend?.trim() || 
      !topic?.trim() || 
      !nameCustomer?.trim() || 
      !mood?.trim()
    ) {
      setTextHead("Все поля должны быть заполнены");
      setEmpty(1);
    } else {
      setTextHead("Заполните все поля:");
      setEmpty(0);
      VkAds();
      setVisAd(true);
      // console.log("Запрос");
      setTimeout(async () => {
        await handleSubmit();
      }, 1500);
    }
  }
  
  //информационное окно об успешность копирования
  const [copySuccess, setCopySuccess] = useState(null)

  const copyText = () => {
    bridge.send('VKWebAppCopyText', {
      text: message
      })
      .then((data) => { 
        if (data.result) {
          setCopySuccess('Скопировано');
          setTimeout(()=>{setCopySuccess(null)},1000)
        } else {
          setCopySuccess('Ошибка');
          setTimeout(()=>{setCopySuccess(null)},1000)
        }
      })
      .catch((error) => {
        setCopySuccess('Ошибка');
        setTimeout(()=>{setCopySuccess(null)},1000)
        console.log(error);
      });
  }

const [animeStart, setAnimeStart] = useState(false)
const [animeInp, setAnimeInp] = useState(false)
  const getVisStart = () =>{
    setTimeout(()=>{setVisStartScreen(!visStartScreen)},700) 
    setTimeout(()=>{setAnimeInp(true)},700) 
    setAnimeStart(true)
  }
  const getVisBottom = () =>{
    setVisBottom(!visBottom)
  }
  //Вводим только кириллицу в инпуты
  const changeName = (e)=> {
    const value = e.target.value;
    const regex = /^[А-Яа-яЁё\s.,-]*$/

    if (regex.test(value)) {
      setNameSend(value)
  }
}
const changeTopic = (e)=> {
  const value = e.target.value;
  const regex = /^[А-Яа-яЁё\s0-9.,-]*$/;

  if (regex.test(value)) {
    setTopic(value)
}
}

const changeCustomer = (e)=> {
  const value = e.target.value;
  const regex = /^[А-Яа-яЁё\s.,-]*$/

  if (regex.test(value)) {
    setNameCustomer(value)
}
}
const changeMood = (e)=> {
  const value = e.target.value;
  const regex = /^[А-Яа-яЁё\s.,-]*$/

  if (regex.test(value)) {
    setMood(value)
}
}

  return (
    <div className="cont">
      {/* <WindowAd/> */}
       {visAd && <WindowAd/>}
        {/* <ErrorScreen/> */}
       {visError && <ErrorScreen/>}
      <div className="window">
        <div className="contHead">
          <div className="textes">
          <p className="headText1">Поздравляйка</p>
          <p className="downText">лучшие поздравления для близких</p>
          </div>
          <div className="head">
            <img className="img1" alt="1" src={people}/>
            <img className="img2" alt="1" src={letter}/>
            <img className="img3" alt="1" src={people}/>
          </div>
        {visStartScreen ? <div/>: message? <p className="textUp">Ваше поздравление готово!</p>:<p className="textUp">Давайте составим поздравление</p>}
        </div>
      {visStartScreen ? 
      <div className={animeStart ? "start-container-anime": "start-container"}>
      <p className="textStartHead" >
        "Поздравляйка" - это ваше персональное приложение для создания уникальных поздравлений!
      </p>
      <br/>
      <p className="textStart" style={{}}>
        Всего за несколько секунд и абсолютно бесплатно вы сможете порадовать близких и друзей оригинальными пожеланиями на любую тематику и в любом стиле.
      </p>
      <br/>
      <p className="textStartHead" >Как это работает?</p>
      <br/>
      <ul className="textStart" style={{textAlign:"left"}}>
        <li className="textStart">Заполните необходимые поля.</li>
        <li className="textStart">Укажите, кого планируете поздравить, от кого будет поздравление, тему и стиль поздравления.</li>
        <li className="textStart">Получите уникальное поздравление, созданное специально для вас!</li>
      </ul>
      <br/>
      <p className="textStart" style={{fontWeight:"bold"}}>
        Неограниченное количество поздравлений и моментальное составление текста - это "Поздравляйка" <br/><br/>Подарите улыбки своим близким!
      </p>
      <br/>
        <button className="btn-start" onClick={getVisStart} style={{margin:"auto"}}>Начать</button>
      </div>:

      !message ? 

      <div className={animeInp ? "form-container-anime": "form-container"}>
        <h3 
        style={{color: empty===1 ? "#b21f1f": "#1a2a6c"}}
        >{textHead}</h3>
        {/* <p className="subtitle">заполните все поля</p> */}
        <div className="form-group">
          <input type="text" placeholder="Кого будем поздравлять?" onChange={(e)=>changeName(e)} value={nameSend} maxLength="30"/>
        </div>
        <div className="form-group">
          <input type="text" placeholder="С чем поздравим?" onChange={(e)=>changeTopic(e)} value={topic} maxLength="30"/>
        </div>
        <div className="form-group">
          <input type="text" placeholder="От кого поздравление?" onChange={(e)=>changeCustomer(e)} value={nameCustomer} maxLength="30"/>
        </div>
        <div className="form-group">
          <input type="text" placeholder="Стиль: любовный, семейный, шуточный..." onChange={(e)=>changeMood(e)} value={mood} maxLength="30"/>
        </div>
        <div className="keep-signed-in">
          <input type="checkbox" id="keepSignedIn" onChange={(e)=>setSmile(e.target.checked)} checked={smile}/>
          <label htmlFor="keepSignedIn" style={{marginLeft:"10px"}}>Использовать смайлики?</label>
        </div>
        <button className="create-button" onClick={main}>Написать</button>
      </div>: 
         <div className="contMessage">
           {/* <p className="headTextMes">Ваше поздравление готово!</p> */}
            <p className="textMessage" >{message}</p>
          <div className="copySend" >
         {/* <p className="textModal">Скопировано</p> */}
            {copySuccess && <p className="textModal">{copySuccess}</p>}
            <button className="btnCopy" onClick={copyText}>Копировать</button>
            <button className="btnAgain" onClick={()=>setMessage(null)}>Еще раз</button>
         </div>
         </div>
}
            <MenuBottom getVisBottom={getVisBottom} />
      </div>
      </div>
  );
};

export { ChatComponent };
