import './MenuBottom.css'
// import add from './image/add.png'
import share from './image/share.png'
// import VkInvite from "./VkInvite";
// import VkHomeScreen from './VkHomeScreen';
import VkShare from './vkShare';
// import VkFavorite from './vkFavorite.js'
import favorite from './image/favorite.png'
import rec from './image/rec.png'
import VkRec from './vkRec'
import {useState } from 'react';
import bridge from '@vkontakte/vk-bridge';

const MenuBottom = () => {
    const [visModalAdd, setVisModalAdd] = useState(false)

    const handleAddClick = () => {
        bridge.send('VKWebAppAddToFavorites')
        .then((data) => { 
          if (data.result) {
            setVisModalAdd(true)
            setTimeout(()=>{setVisModalAdd(false)},1500)
          }
        })
        .catch((error) => {
          // Ошибка
          console.log("ошибочка")
          console.log(error);
        });
      
      }


        return (
                <div className="contBotton">
                    <div className="btnMenu" onClick={VkRec}>
                        <img src={rec} alt="+" className="imgMenu"/>
                    </div>
                    <div className="btnMenu" style={{position:"relative"}}>
                        {visModalAdd && <p className="modalAdd">Добавлено</p>}
                        <img src={favorite} alt="+" className="imgMenu" onClick={handleAddClick}/>
                    </div>
                    {/* <div className="btnMenu">
                        <img src={add} alt="+" className="imgMenu" onClick={VkHomeScreen}/>
                    </div> */}
                    <div className="btnMenu" onClick={VkShare}>
                        <img src={share} alt="+" className="imgMenu" style={{marginRight:"2px"}}/>
                    </div>
                </div>
        )
}

export default MenuBottom