import './WindowAd.css'

const ErrorScreen = () => {
    return (
        <div className="contAd">
            <div className="windowAd">
                <p className="textAd" style={{marginBottom:"20px", marginTop:"10px"}}>К осжалению произошла ошибка!</p>
                <hr/>
                <p className="textAd" style={{fontWeight:"normal", marginTop:"20px"}}>Повторите запрос позднее.</p>
            </div>
        </div>
    )
}

export default ErrorScreen