import bridge from '@vkontakte/vk-bridge';
// import { useEffect } from 'react';

const VkAds = () => {
  // console.log("Нажали на рекламу")
  bridge.send('VKWebAppShowNativeAds', { ad_format: 'reward' })
    .then((data) => {
      if (data.result) {
        // func();
        // console.log("Реклама показана")
      }
      else // Ошибка 
        console.log('Ошибка при показе');
    })
    .catch((error) => { console.log(error); /* Ошибка */ });

return 

}

export default VkAds