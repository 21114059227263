import './WindowAd.css'
import letterGif from './image/letterGif.gif'

const WindowAd = () => {
    return (
        <div className="contAd">
            <div className="windowAd">
                <img src={letterGif} alt="/" style={{width:"50px", height:"50px"}}/>
                <p className="textAd" style={{marginBottom:"20px"}}>Поздравление будет готово через мгновение!</p>
                <hr/>
                <p className="textAd" style={{fontWeight:"normal", marginTop:"20px"}}>Пока оно создаётся, наслаждайтесь коротким рекламным роликом.</p>
            </div>
        </div>
    )
}

export default WindowAd