import './App.css';
import { useEffect } from 'react';
import { ChatComponent } from './components/ChatComponent';
import bridge from '@vkontakte/vk-bridge';

function App() {

  useEffect(()=>{
    bridge.send("VKWebAppInit", {});
    bridge.send('VKWebAppCheckNativeAds', { ad_format: 'reward' })
    .then((data) => {
      // console.log("Запрос в VK выполнен успешно");
      if (data.result) {
        // console.log("Реклама загружается...");
        // Дополнительные действия при наличии рекламы.
        console.log("Реклама загружена успешно");
      } else {
        // console.log('Рекламные материалы не найдены.');
      }
    })
    .catch((error) => { 
      console.error("Ошибка при выполнении запроса в VK:", error); 
    });
  })
  return (

    <div className="App">
      <ChatComponent/>
    </div>
  );
}

export default App;
