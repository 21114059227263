import bridge from '@vkontakte/vk-bridge';

const VkShare = () => {
    bridge.send('VKWebAppShare', {
        link: 'https://vk.com/app51651648'
        })
        .then((data) => { 
          if (data.result) {
            // Запись размещена
          }
        })
        .catch((error) => {
          // Ошибка
          console.log(error);
        });
}

export default VkShare