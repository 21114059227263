import bridge from '@vkontakte/vk-bridge';

const VkRec = () => {
  console.log("нажали кнопочку")
    bridge.send('VKWebAppRecommend')
  .then((data) => { 
    if (data.result) {
      // Мини-приложение порекомендовано
    }
  })
  .catch((error) => {
    // Ошибка
    console.log(error);
  });
}

export default VkRec